import { Col, Row, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AlertaIcono, CantidadTrabajadoresIcono } from "../../../../util/IconosSvg";
import { VALOR_DEFECTO_ENTERO, VALOR_DEFECTO_STRING } from "../../../../constants/constantes";
const { format } = require("rut.js");

export const Formulario = ({ formulario, actualizarCampo, actualizarIncremento, actualizarDecremento}) => {

  let strRutClaseError    = formulario.rutValido ? "":"form-error"; 
  let strCorreoClaseError = formulario.correoValido ? "":"form-error"; 
  let strRutContratante   = formulario.rut ? format(formulario.rut) : VALOR_DEFECTO_STRING;
  return (
    <>
    <Row>
      <Col md="12" lg="6">
        <Form.Group className={`form-contenedor`} controlId="rut">
          <Form.Label>Rut Empresa Contratante</Form.Label>
          <Form.Control 
            className={strRutClaseError}
            name="rut"
            type="text" 
            value={strRutContratante} 
            onChange={actualizarCampo} 
            />
            { !formulario.rutValido && (
              <OverlayTrigger
              key="rut-error"
              placement="top"
              overlay={ <Tooltip id={`tooltip-rut`}> Rut invalido </Tooltip> }
              >
                <span className="form-icono"><AlertaIcono/></span>
              </OverlayTrigger>
            )}
        </Form.Group>
      </Col>
      <Col md="12" lg="6">
        <Form.Group className={`form-contenedor ${strCorreoClaseError}`} controlId="correo">
          <Form.Label>Email Contratante</Form.Label>
          <Form.Control 
            className={strCorreoClaseError}
            name="correo"
            type="email" 
            value={formulario.correo || VALOR_DEFECTO_STRING} 
            onChange={actualizarCampo} 
            />
            { !formulario.correoValido && (
              <OverlayTrigger
              key="rut-correo"
              placement="top"
              overlay={ <Tooltip id={`tooltip-correo`}> Email invalido </Tooltip> }
              >
                <span className="form-icono"><AlertaIcono/></span>
              </OverlayTrigger>
            )}
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col md="12" lg="6" className="contador">
        <span className="contador-image"><CantidadTrabajadoresIcono /></span>
        <Form.Group className="form-contenedor">
          <Form.Label>Cantidad de Trabajadores</Form.Label>
          <Form.Control
            name="trabajadoresCantidad"
            type="number" 
            onChange={actualizarCampo} 
            value={formulario.trabajadoresCantidad || VALOR_DEFECTO_ENTERO} 
            />
        </Form.Group>
        <span className="contador-controles">
          <Button name="trabajadoresCantidad" onClick={actualizarIncremento} variant="light" className="mb-1">+</Button> 
          <Button name="trabajadoresCantidad" onClick={actualizarDecremento} variant="light">-</Button> 
        </span>
      </Col>
    </Row>
    </>
  ) 
}