import dayjs from "dayjs";
import { 
  CAMPO_EMPRESA_COMUNA,
  CAMPO_EMPRESA_REGION,
  CONVERSION_POSICION_LECTURA,
  DIEZ,
  ERROR_COMIENZO_CERO,
  ERROR_COMUNA_INVALIDA,
  ERROR_CONTIENE_PUNTOS,
  ERROR_ESPACIOS, 
  ERROR_FALTAN_CABECERAS_ARCHIVO, 
  ERROR_FALTAN_CABECERAS_FILA, 
  ERROR_FECHA_INICIO, 
  ERROR_FORMATO_EMAIL, 
  ERROR_FORMATO_FECHA, 
  ERROR_FORMATO_RUT, 
  ERROR_REGION_INVALIDA, 
  ERROR_SIMBOLO_DIA_DESCONTAR, 
  ERROR_VALOR_VACIO, 
  FALSO,
  FECHA_POSICION_ANIO,
  FECHA_POSICION_DIA,
  FECHA_POSICION_MES,
  NUEVE,
  ONCE,
  POCISION_INICIAL_DEFECTO, 
  POCISION_UNO, 
  REGEX_COMIENZO_CERO, 
  REGEX_CONTIENE_PUNTOS, 
  REGEX_CORREO_ELECTRONICO, 
  REGEX_ESPACIOS_BLANCOS, 
  REGEX_VALIDAR_RUT,
  REGIONES_CON_COMUNAS,
  SEIS,
  TIPO_STRING,
  UNO,
  VALOR_CONVERSION_HORAS,
  VALOR_CONVERSION_MILSEGUNDOS,
  VALOR_CONVERSION_MINUTOS,
  VALOR_CONVERSION_SEGUNDOS,
  VALOR_CORRECCION_ANIO,
  VALOR_CORRECCION_MES,
  VALOR_DEFECTO_ENTERO,
  VALOR_K_MAYUSCULA,
  VALOR_K_MINUSCULA,
  VALOR_NULL_STRING,
  VALOR_SIGNO_GUION,
  VALOR_SIGNO_GUION_RARO,
  VALOR_SLASH,
  VALOR_VACIO,
  VERDADERO 
} from "../constants/constantes";

let diasADescontar;

const validarEspacioBlanco = objValor => {

  if (validarValorInvalido(objValor)) return;

  if (REGEX_ESPACIOS_BLANCOS.test(objValor)) return ({
    mensaje: ERROR_ESPACIOS,
    error: VERDADERO
  })
}

const validarValorVacio = objValor => {
  var blnResultado = FALSO;
  if ( !objValor || objValor === VALOR_NULL_STRING) blnResultado = VERDADERO;

  if (typeof objValor === TIPO_STRING) {
      if (objValor.trim() === VALOR_VACIO) blnResultado = VERDADERO;
  }
  if (blnResultado) return ({
    mensaje: ERROR_VALOR_VACIO,
    error: VERDADERO
  })
}

const validarEmpiezaConCero = (objValor) => {
    
  if (validarValorInvalido(objValor)) return;
  
  if (!objValor) return;
  
  if (REGEX_COMIENZO_CERO.test(objValor)) return ({
    mensaje: ERROR_COMIENZO_CERO,
    error: VERDADERO
  })
}

const validarContienePuntos = objValor => {
    
  if (validarValorInvalido(objValor)) return;
  
  if (REGEX_CONTIENE_PUNTOS.test(objValor)) return ({
    mensaje: ERROR_CONTIENE_PUNTOS,
    error: VERDADERO
  })
}

const validarEmail = objValor => {
    
  if (validarValorInvalido(objValor)) return;
  if (!REGEX_CORREO_ELECTRONICO.test(objValor)) return ({
    mensaje: ERROR_FORMATO_EMAIL,
    error: VERDADERO
  })
}

const validarRut = objValor => {
  if (validarValorInvalido(objValor)) return;
  
  var lstValidacion = {
    validaRut: function (strRutCompleto) {
      if (!REGEX_VALIDAR_RUT.test(objValor)){ 
          return FALSO;
      }
      strRutCompleto = strRutCompleto.replace(VALOR_SIGNO_GUION_RARO, VALOR_SIGNO_GUION);
      if (!REGEX_VALIDAR_RUT.test(strRutCompleto)){
          return FALSO;
      }
      var strtemporal     = strRutCompleto.split(VALOR_SIGNO_GUION);
      var strDigVericador = strtemporal[POCISION_UNO];
      var strRut          = strtemporal[POCISION_INICIAL_DEFECTO];
      if (strDigVericador === VALOR_K_MAYUSCULA) strDigVericador = VALOR_K_MINUSCULA;

      return (lstValidacion.validardigito(strRut).toString() === strDigVericador);
    },
    validardigito: function (strRut) {
      var intM = VALOR_DEFECTO_ENTERO, intS = UNO;
      for (; strRut; strRut = Math.floor(strRut / DIEZ))
          intS = (intS + strRut % DIEZ * (NUEVE - intM++ % SEIS)) % ONCE;
      return intS ? intS - UNO : VALOR_K_MINUSCULA;
    }
  }
  if (!lstValidacion.validaRut(objValor)) return ({
    mensaje: ERROR_FORMATO_RUT,
    error: VERDADERO
  })
}

const validarFechaInicio = (objFecha) => {

  let datFechaInicio = objFecha;
  if (validarValorInvalido(objFecha)) return;

  if (typeof objFecha === TIPO_STRING){
    let objTemporal       = objFecha.split(VALOR_SLASH);
    let intAnioCorreccion = Number(objTemporal[FECHA_POSICION_ANIO]) < VALOR_CORRECCION_ANIO ? Number(objTemporal[FECHA_POSICION_ANIO]) + VALOR_CORRECCION_ANIO : Number(objTemporal[FECHA_POSICION_ANIO]);
    let intMesCorrecion   = Number(objTemporal[FECHA_POSICION_MES]) - VALOR_CORRECCION_MES;
    datFechaInicio        = new Date(Number(intAnioCorreccion), intMesCorrecion, Number(objTemporal[FECHA_POSICION_DIA]));
  }

  if (!dayjs(datFechaInicio).isValid())
    return ({
        mensaje: ERROR_FORMATO_FECHA + objFecha,
        error: VERDADERO
    });

  let datFechaActual = new Date();
  datFechaActual     = new Date(datFechaActual.getFullYear(), datFechaActual.getMonth(), datFechaActual.getDate());

  let intDifeMilisegundo = datFechaActual - datFechaInicio;
  let intDiffDia         = Math.ceil(intDifeMilisegundo / ( VALOR_CONVERSION_MILSEGUNDOS * VALOR_CONVERSION_SEGUNDOS * VALOR_CONVERSION_MINUTOS * VALOR_CONVERSION_HORAS));

  if (intDiffDia > diasADescontar) return ({
    mensaje: ERROR_FECHA_INICIO.replace(ERROR_SIMBOLO_DIA_DESCONTAR,diasADescontar),
    error: VERDADERO
  })
}

const validarRegion = objValor => {
    
  if (validarValorInvalido(objValor)) return;

  var blnResultado = Boolean( REGIONES_CON_COMUNAS.find(objElemento => { return objElemento[objValor] }))

  if (!blnResultado) return ({
    mensaje: ERROR_REGION_INVALIDA,
    error: VERDADERO
  })
}

const validarComuna = (objValor, strRegion) => {

  if (validarValorInvalido(objValor)) return;
  let strComuna = null;
  let objRegion = REGIONES_CON_COMUNAS.find(objElemento =>  objElemento[strRegion]);

  if (objRegion) {
    strComuna = objRegion[strRegion].find( objComuna =>  objComuna === objValor);
  }
  if (!strComuna) return ({
    mensaje: ERROR_COMUNA_INVALIDA,
    error: VERDADERO
  })
}

const validarExistenTodasLasCabeceras = (lstCabecerasRevisable = {}, blnFila) => {

  const lstCabecerasRequerida = Object.keys(lstValidacionRequerida)
  const lstResultado = lstCabecerasRequerida.filter(objCabecera => !lstCabecerasRevisable.find( objRevisable => objCabecera === objRevisable)  )

  if (lstResultado.length > VALOR_DEFECTO_ENTERO){
    let strInicioError = blnFila ? ERROR_FALTAN_CABECERAS_FILA : ERROR_FALTAN_CABECERAS_ARCHIVO
    return ({
      mensaje: `${strInicioError} ${lstResultado.join(", ")}`,
      error: VERDADERO
    })
  } 
}

const validarValorInvalido = (objValor) => {
  if (!objValor) return VERDADERO;

  if (typeof objValor === TIPO_STRING) {
    if (!objValor.trim() || objValor === VALOR_NULL_STRING ) return VERDADERO;
  }
  return FALSO
}

const lstValidacion = {
  EmpresaRut: [validarRut, validarContienePuntos, validarEspacioBlanco, validarEmpiezaConCero, validarValorVacio],
  EmpresaRazonSocial: [validarValorVacio],
  EmpresaGiro: [validarValorVacio],
  EmpresaDomicilioCalle: [validarValorVacio],
  EmpresaDomicilioRegion: [validarRegion, validarValorVacio],
  EmpresaDomicilioComuna: [validarComuna, validarValorVacio],
  EmpresaCorreo: [validarEmail, validarValorVacio],
  ClienteCorreo: [validarEmail, validarValorVacio],
  ClienteCorreoConfirmacion: [validarEmail, validarValorVacio],
  ClienteRUT: [validarRut, validarContienePuntos, validarEspacioBlanco, validarEmpiezaConCero, validarValorVacio],
  ClienteNombre1: [validarValorVacio],
  ClienteApellido1: [validarValorVacio],
  ClienteApellido2: [validarValorVacio],
  FechaInicio: [validarFechaInicio, validarValorVacio],
  EmpresaCorreoConfirmacion: [validarEmail, validarValorVacio],
  CorredorRut: [validarRut, validarContienePuntos, validarEmpiezaConCero],
  CorredorRazonSocial: [],
  PrimaBrutaActual: [],
  FechaFin: []
}

const lstValidacionRequerida = {
  EmpresaRut: [validarRut, validarContienePuntos, validarEspacioBlanco, validarEmpiezaConCero, validarValorVacio],
  EmpresaRazonSocial: [validarValorVacio],
  EmpresaGiro: [validarValorVacio],
  EmpresaDomicilioCalle: [validarValorVacio],
  EmpresaDomicilioRegion: [validarRegion, validarValorVacio],
  EmpresaDomicilioComuna: [validarComuna, validarValorVacio],
  EmpresaCorreo: [validarEmail, validarValorVacio],
  ClienteCorreo: [validarEmail, validarValorVacio],
  ClienteCorreoConfirmacion: [validarEmail, validarValorVacio],
  ClienteRUT: [validarRut, validarContienePuntos, validarEspacioBlanco, validarEmpiezaConCero, validarValorVacio],
  ClienteNombre1: [validarValorVacio],
  ClienteApellido1: [validarValorVacio],
  ClienteApellido2: [validarValorVacio],
  FechaInicio: [validarFechaInicio, validarValorVacio],
  EmpresaCorreoConfirmacion: [validarEmail, validarValorVacio]
}

export const ValidarNomina = ({ archivo, cabeceras }, nominaConfig) => {

  let lstError             = [];
  diasADescontar           = nominaConfig.diasDescontar;
  let objValidacionArchivo = validarExistenTodasLasCabeceras(cabeceras, FALSO);

  archivo.forEach((objFila, intFila) => {
    let lstCabecera = [];
    
    Object.keys(objFila).forEach(strCabecera => { lstCabecera.push(strCabecera) });
    let objValidacionCabecera = validarExistenTodasLasCabeceras(lstCabecera, VERDADERO);

    if (objValidacionCabecera) {
      lstError.push({
        id: Number(lstError.length + CONVERSION_POSICION_LECTURA),
        error: `${objValidacionCabecera.mensaje}`,
        valor: '--',
        fila: Number(intFila + CONVERSION_POSICION_LECTURA),
        columna: '--',
      });
    }

    Object.keys(objFila).forEach((objColumna) => {
      if (lstValidacion[objColumna]) {
        lstValidacion[objColumna].forEach(objValidacion => {

          let objResultadoValidacion = null;
          switch (objColumna) {
            case CAMPO_EMPRESA_COMUNA:
              objResultadoValidacion = objValidacion(objFila[objColumna], objFila[CAMPO_EMPRESA_REGION]);
              break;
        
            default:
              objResultadoValidacion = objValidacion(objFila[objColumna]);
              break;
          }

          if (objResultadoValidacion) {
            lstError.push({
                id: Number(lstError.length + CONVERSION_POSICION_LECTURA),
                error: `${objResultadoValidacion.mensaje}`,
                valor: objFila[objColumna],
                fila: Number(intFila + CONVERSION_POSICION_LECTURA),
                columna: objColumna,
            });
          }
        });
      }
    });
  });
  let strMensaje = objValidacionArchivo ? objValidacionArchivo.mensaje : null;
  return { errorCabeceraArchivo: strMensaje, erroresData: lstError};
}