import React, { useEffect, useState } from 'react'
import { DbContexto } from './DbContexto'
import { buscarColeccion, configurarDb, guardarDocumento, actualizarDocumento } from '../../config/Firebase';
import { 
  COLECCION_CONFIGURACION, 
  COLECCION_NOMINA, 
  NOTIFICACION_ERROR_CONECCION_FIREBASE, 
  NOTIFICACION_TIPO_ERROR } from '../../constants/constantes';
import { mostrarNotificacion } from '../../util/Notificacion';

export const DbProveedor = ({children}) => {

  const [db, setDb] = useState();

  const buscarConfiguracionDb = async () => {
    
    return await buscarColeccion(db, COLECCION_CONFIGURACION);
  }

  const buscarNominaDb = async () => {
    return await buscarColeccion(db, COLECCION_NOMINA);
  }

  const guardarNominaDb = async ( objNomina ) => {
    
    return await guardarDocumento(db, objNomina, COLECCION_NOMINA);
  }

  const guardarPrecioDb = async ( objPrecio ) => {
    
    return await guardarDocumento(db, objPrecio, COLECCION_CONFIGURACION);
  }

  const actualizarNomina = async ( strIdDocumento, objCamposNomina ) => {
    
    return await actualizarDocumento(db, COLECCION_NOMINA, strIdDocumento, objCamposNomina);
  }

  useEffect(() => {

    new Promise((liberarConfiguracion) => {
      liberarConfiguracion(configurarDb());
    }).then((objDb) =>{
      setDb(objDb);
    }).catch(()=>{
      mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CONECCION_FIREBASE);
    })
  }, [])
  
  return (
    <DbContexto.Provider value={{ 
      db,
      buscarConfiguracionDb, 
      guardarNominaDb, 
      guardarPrecioDb, 
      buscarNominaDb,
      actualizarNomina }}>
    { children }
    </DbContexto.Provider>
  )
}
