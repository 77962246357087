import { Store  } from "react-notifications-component";
import { AlertaIcono, CerrarIcono, CheckIcono } from "./IconosSvg";

const construirNotificacion = (strTipo, strMensaje) => {
  let objIcon;

  switch (strTipo) {
    case "info":
      objIcon = <AlertaIcono />
      break;

    case "warning":
      objIcon = <AlertaIcono />
    break;

    case "danger":
      objIcon = <CerrarIcono />
      break;
  
    default:
      //"success"
      objIcon = <CheckIcono />
      break;
  }

  return (
    <div className={`animated fadeIn rnc__notification-item rnc__notification-item--${strTipo}`}>
      <div className="rnc__notification-content">
        <div className="rnc__notification-close"></div>
        <p className="rnc__notification-message">
          <span className="rnc__notification-icono">{objIcon}</span>
          <span>{strMensaje}</span>
        </p>
      </div>
    </div>
  );
}

export const mostrarNotificacion = (strTipo, strMensaje) => {

  return Store.addNotification({
    content: construirNotificacion(strTipo, strMensaje),
    container: "top-full",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000
    }
  });
}