import { useState } from "react";
import { VALOR_TAMANO_VACIO, VERDADERO } from "../../constants/constantes";

export const useArchivoCargado = (objInicial = {}) => {

  const [objArchivo, setArchivo] = useState(objInicial);

  const guardarArchivo = (objArchivoRecivido) => {
    setArchivo({
      ...objArchivo,
      ...objArchivoRecivido
    })
  }

  const guardarErrores = (lstError) => {
    let {errorCabeceraArchivo, erroresData} = lstError;
    let blnHayError = (errorCabeceraArchivo !== undefined && errorCabeceraArchivo !== null) || (erroresData.length > VALOR_TAMANO_VACIO) ;

    setArchivo({
      ...objArchivo,
      errores: {...lstError},
      hayError: blnHayError,
      verificado: VERDADERO
    })
  }

  const eliminarArchivo = () => {
    document.querySelectorAll('input[type=file]').value = null;
    setArchivo({
      ...objInicial
    })
  }

  return { archivo: objArchivo, guardarArchivo, eliminarArchivo, guardarErrores}
}