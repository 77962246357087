import { Cabecera } from './Cabecera/Cabecera';
import { PiePagina } from './PiePagina/PiePagina';
import { Contenido } from './Contenido/Contenido';
import { ConfiguracionProveedor } from '../../reducer/Configuracion/ConfiguracionProveedor';
import { PeticionInterceptor } from '../../config/PeticionInterceptor';
import { DbProveedor } from '../../reducer/BaseDeDatos/DbProveedor';
import { ReactNotifications } from 'react-notifications-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css'
import "../assets/css/estilos.css";


export const Formulario = () => {
  
  return (
    <>
      <ReactNotifications />
      <DbProveedor>
        <ConfiguracionProveedor>
          <PeticionInterceptor>
            <Cabecera />
            <Contenido />
            <PiePagina />
          </PeticionInterceptor>
        </ConfiguracionProveedor>
      </DbProveedor>
    </>
  );
}
