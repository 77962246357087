import React from 'react'
import { ArchivoIcono, CheckIcono, PapeleraIcono } from '../../../../util/IconosSvg';

export const NominaInformacion = ({ objArchivo, eliminarArchivo }) => {
  
  let strAdjuntoDetalle  = "adjunto_detalle--grisClaro";
  let strIcoAdjuntoAccion = "";

  if (objArchivo.archivoCargado) {
    strAdjuntoDetalle  = "adjunto_detalle--gris";
    strIcoAdjuntoAccion = "adjunto_accion--cargado";
  }

  if (objArchivo.hayError) {
    strAdjuntoDetalle = "adjunto_detalle--error";
  }

  return (
    <section className="adjunto">
      <span className="adjunto_titulo">Documento Adjunto</span>
      <section className={`adjunto_detalle ${strAdjuntoDetalle}`} >
        <span className={`adjunto_icono`} >
          <ArchivoIcono />
        </span>
        <p className="adjunto_descripcion">
          <span>{objArchivo.nombre}</span>
          <span>{objArchivo.fecha} - {objArchivo.tamano}</span>
        </p>
        { objArchivo.archivoCargado && !objArchivo.hayError && (
            <span className={`adjunto_accion ${strIcoAdjuntoAccion}`}>
              <CheckIcono />
            </span>
        )}
        { objArchivo.archivoCargado && objArchivo.hayError && (
            <button className={`adjunto_eliminar`} onClick={eliminarArchivo}>
              <PapeleraIcono />
            </button>
        )}
      </section>
    </section>
  )
}
