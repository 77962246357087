import { Col, Row } from "react-bootstrap"
import { UsuarioIcono } from "../../../../util/IconosSvg"
import { Precio } from "./Precio"
import { useContext } from "react"
import { ContratanteContexto } from "../../../../reducer/Contratante/ContratanteContexto"
import { Formulario } from "./Formulario"

export const Contratante = () => {

  const { formulario, actualizarCampo, actualizarIncremento, actualizarDecremento } = useContext(ContratanteContexto);

  return (
    <section className="datos">
      <header className="datos_cabecera">
        <h6 className="datos_cabeceraTitulo">
          <UsuarioIcono />
          <span>Datos de contacto de la empresa</span>
        </h6>
      </header>
      <p>Ingresa tus datos para contratar el seguro</p>
      <Row>
        <Col md="12" lg="8">
          <Formulario formulario={formulario} actualizarCampo={actualizarCampo} actualizarIncremento={actualizarIncremento} actualizarDecremento={actualizarDecremento}/>
        </Col>
        <Col md="12" lg="4">
          <Precio formulario={formulario}  />
        </Col>
      </Row>
    </section>
  ) }