import { Row, Col} from "react-bootstrap";
import { TrabajadoresIcono, DocumentoIcono, CheckIcono } from '../../../../util/IconosSvg';
import { PASOS } from '../../../../constants/constantes';

const Paso = ({objPaso}) => {
  let strNombreClaseColor = objPaso.finalizado ? "paso_nombre--finalizado" : "";
  let strPasoClaseColor = objPaso.finalizado ? "paso--finalizado" : "";
  return(
    <li className={`paso ${strPasoClaseColor}`}>
      { objPaso.finalizado ? (
        <span className="paso_icono"> <CheckIcono /> </span>
      ) : (
        <span className="paso_numero">{objPaso.numero}</span>
      )}
      <span className={`paso_nombre ${strNombreClaseColor}`}>{objPaso.nombre}</span>
    </li>
  )
}

export const Informacion = () => {
  return (
    <section className="informacion">
      <h5>Seguro Obligatorio de Salud Asociado al COVID-19</h5>
      <p>Proteger a tus colaboradores con cobertura de salud y vida</p>
      <br />
      <Row>
        <Col md="12" lg="6">
          <figure className="informacion_icono text-center">
            <TrabajadoresIcono />
          </figure>
          <p className="text-center">Este seguro cubre a todos los trabajadores del sector privado con <strong>contratos sujetos al Código del Trabajo</strong> y que estén desarrollando sus <strong>labores de manera presencial, total o parcial.</strong></p>
        </Col>
        <Col md="12" lg="6">
          <figure className="informacion_icono text-center">
            <DocumentoIcono />
          </figure>
          <p className="text-center">El seguro cubre el <strong>100%,</strong> del gasto a pagar por atenciones <strong>hospitalarias y rehabilitación</strong> derivadas de covid 19, siempre y cuando un asegurado <strong>FONASA</strong> se atienda en modalidad institucional o un asegurado <strong>Isapre</strong> se atienda en su red CAEC correspondiente.</p>
        </Col>
        <Col md="12" lg="12" className="text-center">
          <br />
          <p className="text-center">En el caso de las Isapres, el copago CAEC equivaldría a 30 cotizaciones del afiliado, con un mínimo de UF 60 y máximo UF 126</p>
          <br />
          <br />
          <h5>¿Cómo contratar el seguro?</h5>
          <p>Sigue estos pasos y finalizado el proceso de contratación, te enviaremos la información de todas las pólizas</p>
          <ul className="paso_contenedor">
            {PASOS.map((objPaso, intIndice) => (
              <Paso key={intIndice} objPaso={objPaso} />
            ))}
          </ul>
        </Col>
      </Row>
    </section>
  );
}