import React from 'react';
import DataTable from 'react-data-table-component';
import { VALOR_TAMANO_VACIO } from '../../../../constants/constantes';

const lstColumna = [
  {
    id: 'fila',
    name: 'Fila',
    selector: row => row.fila,
    sortable: true,
    wrap: true,
    minWidth: "80px"
  },
  {
    id: 'columna',
    name: 'Columna',
    selector: row => row.columna,
    sortable: true,
    wrap: true,
    minWidth: "200px"
  },
  {
    id: 'valor',
    name: 'Valor',
    selector: row => row.valor,
    sortable: true,
    wrap: true,
    minWidth: "200px"
  },
  {
    id: 'error',
    name: 'Error',
    selector: row => row.error,
    sortable: true,
    wrap: true,
    minWidth: "500px"
  },
];

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

export const NominaError = ({errores}) => {
  return (
    <>
    <h6 className="errores_titulo">Errores encontrados en la nómina</h6>
    
    { errores.errorCabeceraArchivo && (
      <p className="errores_archivo"> <strong>Error general</strong> <br /> {errores.errorCabeceraArchivo}</p>
    )}

    { errores.erroresData.length > VALOR_TAMANO_VACIO && (
      <DataTable 
        title="Nomina Errores"
        columns={lstColumna}
        data={errores.erroresData}
        dense="true"
        responsive="true"
        pagination="false"
        paginationPerPage={10}
        paginationComponentOptions={ paginationComponentOptions }
        striped="true"
        defaultSortFieldId={1}
        noHeader={"true"}
        fixedHeader={"true"}
        fixedHeaderScrollHeight="200px"
        direction="auto"
        subHeaderAlign="center"
      />
    )}
    </>
  )
}
