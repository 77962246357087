import { useContext, useEffect, useState } from 'react';
import { DbContexto } from '../BaseDeDatos/DbContexto';
import { ConfiguracionContexto } from './ConfiguracionContexto';
import { buscarGatewayToken, buscarUfPorFecha } from '../../service/ConfiguracionService';
import { mostrarNotificacion } from '../../util/Notificacion';
import dayjs from 'dayjs';
import { 
  COD_MONEDA_UF, 
  FORMATO_FECHA_MOSTRAR, 
  FORMATO_FECHA_PETICION_UF,
  POCISION_INICIAL_DEFECTO,
  NOTIFICACION_ERROR_TOKEN,
  NOTIFICACION_TIPO_ERROR,
  NOTIFICACION_ERROR_UF,
  NOTIFICACION_ERROR_UF_VACIO,
  NOTIFICACION_ERROR_CONFIGURACION_FIREBASE,
  VALOR_DEFECTO_ENTERO,
  INICIO_AUTENTICACION_TOKEN,
  VALOR_TOKEN,
  DECIMALES_UF
} from '../../constants/constantes';

const objPrecioInicializacion = {
  precioBaseCLP: 0,
  precioBaseUF: 0,
  valorUF: 0,
  fechaUF: null,
  configurado: false,
}

const objNominaConfigInicializacion = {
  diasDescontar:0,
  milSegundosDia: 0,
  configurado: false,
}

const configurarValores = async (setPrecio, setNominaConfig, objDb) => {

  let strFechaPeticion = dayjs().format(FORMATO_FECHA_PETICION_UF);
  let strFechaMostrar  = dayjs().format(FORMATO_FECHA_MOSTRAR);

  await buscarUfPorFecha(COD_MONEDA_UF, strFechaPeticion).then(async (objRespuesta) => {

    let objConfiguracion = await objDb.buscarConfiguracionDb();
    if (objRespuesta && objConfiguracion && objConfiguracion.length > VALOR_DEFECTO_ENTERO) {

      let { objeto : { valor }} = objRespuesta;
      setPrecio({
        valorUF: Number(valor).toFixed(DECIMALES_UF),
        fechaUF: strFechaMostrar,
        precioBaseUF: Number(objConfiguracion[POCISION_INICIAL_DEFECTO].precioBaseUF).toFixed(DECIMALES_UF),
        precioBaseCLP: (objConfiguracion[POCISION_INICIAL_DEFECTO].precioBaseUF * valor),
        configurado: true,
      });

      setNominaConfig({
        diasDescontar: objConfiguracion[POCISION_INICIAL_DEFECTO].diasDescontar,
        milSegundosDia: objConfiguracion[POCISION_INICIAL_DEFECTO].milSegundosDia,
        configurado: true,
      });
    }
    else{
      if (!objRespuesta) {
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_UF_VACIO);
      }
      if (!objConfiguracion || objConfiguracion.length === VALOR_DEFECTO_ENTERO) {
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CONFIGURACION_FIREBASE)
      }
    }
  }).catch((objError)=>{
    console.error(objError);
    mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_UF);
  });
}

export const ConfiguracionProveedor = ({children}) => {

  const [strToken, setToken]               = useState();
  const [objPrecio, setPrecio]             = useState(objPrecioInicializacion);
  const [objNominaConfig, setNominaConfig] = useState(objNominaConfigInicializacion);
  const objDb = useContext(DbContexto);

  const configurarToken = () => {
    setToken(INICIO_AUTENTICACION_TOKEN+VALOR_TOKEN);
  }

  useEffect(() => {
    if (!(objPrecio.configurado && objNominaConfig.configurado) && strToken) {
      configurarValores(setPrecio, setNominaConfig, objDb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strToken])
  

  useEffect(() => {
    buscarGatewayToken().then(async (objRespuesta)=> {
      setToken(objRespuesta);
    }).catch(()=>{
      mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_TOKEN);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objDb.db])
  
  return (
    <ConfiguracionContexto.Provider value={{ 
        token: strToken, 
        precio: objPrecio, 
        nominaConfig: objNominaConfig, 
        guardarNomina: objDb.guardarNominaDb,
        actualizarNomina: objDb.actualizarNomina,
        configurarToken: configurarToken, 
      }}>
      { children }
    </ConfiguracionContexto.Provider>
  )
}