import { useState } from 'react';
import { validarCorreoElectronico } from '../../util/Funciones';
import { 
  VALOR_DEFECTO_ENTERO, 
  VALOR_DEFECTO_INCREMENTO_DECREMENTO, 
  VALOR_VACIO, 
  REGEX_PUNTOS,
  TIPO_TEXTO,
  TIPO_EMAIL,
  SUFIJO_VALIDO,
  VERDADERO
} from '../../constants/constantes';

const { validate, format } = require("rut.js");

export const useFormulario = (objInicial = {}) => {

  const [objFormulario, setEstadoFormulario] = useState(objInicial);

  const actualizarCampo = ({target}) => {
    let { name, value, type } = target;
    let blnValido             = VERDADERO;

    if (type === TIPO_EMAIL) {
      blnValido = validarCorreoElectronico(value);
    }

    if (type === TIPO_TEXTO) {
      value = format(value)
      blnValido = validate(value);
      value = value.replace(REGEX_PUNTOS, VALOR_VACIO).toUpperCase()
    }

    setEstadoFormulario({
      ...objFormulario,
      [name] : value,
      [name+SUFIJO_VALIDO] : blnValido
    })
  }

  const actualizarIncremento = ({target}) => {
    const { name } = target;

    setEstadoFormulario({
      ...objFormulario,
      [name] : Number(objFormulario[name])+ VALOR_DEFECTO_INCREMENTO_DECREMENTO
    })
  }

  const actualizarDecremento = ({target}) => {
    const { name } = target;

    if (objFormulario[name] === VALOR_DEFECTO_ENTERO) return;

    setEstadoFormulario({
      ...objFormulario,
      [name] : Number(objFormulario[name]) - VALOR_DEFECTO_INCREMENTO_DECREMENTO
    })
  }

  return { formulario: objFormulario, actualizarCampo, actualizarIncremento, actualizarDecremento}
}