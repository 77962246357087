const LogoVC = require('../../assets/images/logo_vida_camara.png');

export const Cabecera = () => {
  return(
    <nav className="cabecera">
      <figure className="cabecera_logo">
        <img src={LogoVC} alt="Logo Vida Camara" />
      </figure>
    </nav>
  )
}