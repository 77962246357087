import Axios from "axios";

export const GET = async (strUrl) => {
  try {
    return await Axios.get(strUrl);
  } catch (objError) {
    console.error(objError);
  }
}

export const POST = async (strUrl,objeto) => {
  try {
    return await Axios.post(strUrl,objeto);
  } catch (objError) {
    console.error(objError);
  }
}
export const ejemplo = async (strUrl,objEmpresa) => {
  var headersPersonalizados = new Headers();
  headersPersonalizados.append("Content-Type", "application/json");
  headersPersonalizados.append('Accept', 'application/json');
  headersPersonalizados.append('Authorization', 'Bearer 3cd6e865-d7c8-3b4e-8a0b-9e1d6e2c3cd0');

  var objOpcionesPeticion = {
    method: "POST",
    headers: headersPersonalizados,
    body: JSON.stringify(objEmpresa),
    redirect: "follow",
  };

  let objResultado = undefined;
  objResultado = await fetch(
    strUrl,
    objOpcionesPeticion
  );

  if (!objResultado.ok) throw new Error();
  return objResultado.json();
};