
import { useEffect } from "react";
import "../assets/css/exitoso.css";
import TagManager from "react-gtm-module";

const LogoVC = require('../assets/images/logo_vida_camara.png');

export const Exitoso = () => {

    useEffect(() => {    
    TagManager.dataLayer({
        dataLayer: {
        event: "traza",
        event_category: "Formulario Covid",
        event_label: "Pago exitoso",
        },
    });
    },[]);
  
  return (
    <div class="container-fluid" data-on-form="">

        <div>
            <div class="cabecera-exitoso">
                <div class="row">
                    <div class="backgroundSale">
                        <div class="CongratSC">&iexcl;Muchas gracias!</div>

                        <div class="TitleConfirmation">Hemos recibido tu pago.</div>
                    </div>
                </div>

                <div class="row">
                    <div class="borderPunteadoSC">
                        <div class="row">
                            <center>
                                <h3>Tus pólizas serán emitidas</h3>
                            </center>
                        </div>

                        <div class="row fondoGrisSC">
                            <div class="row">
                                <div class="col-md-12">
                                    <center>Mañana recibirás un correo electrónico en la casilla utilizada en esta
                                        solicitud, para poder descargar las pólizas emitidas. Ante cualquier
                                        inconveniente no dudes en contactarte con uno de nuestros ejecutivos al
                                        <strong>600 320 0700</strong>.</center>
                                </div>
                            </div>
                            <br/>
                            <div class="row">
                                <div class="col-md-12">
                                    <center>Gracias por <strong>confiar</strong> en:</center>
                                </div>
                            </div>

                            <div class="row">
                                <div class="backgroundSaleCatastrofico">
                                  <a href="https://www.vidacamara.cl/">
                                    <img src={LogoVC} alt="logo-vida-camara" border="0"></img>
                                  </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row margingTop-btn">
                    <center>Te invitamos a visitar <strong>www.vidacamara.cl&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>&nbsp;<a class="btn btn-secundary" href="https://www.vidacamara.cl/">IR AL SITIO</a></center>
                </div>
            </div>
        </div>


    </div>
  );
}
