export const PASOS = [  { numero: 1, nombre: "Datos personales", finalizado: true },
                        { numero: 2, nombre: "Pago", finalizado: false},
                        { numero: 3, nombre: "Confirmación", finalizado: false}
                      ];
export const UNO = 1;
export const SEIS = 6;
export const NUEVE = 9;
export const DIEZ = 10;
export const ONCE = 11;
export const VERDADERO = true;
export const FALSO = false;
export const CODIGO_PRODUCTO = "13209-";
export const POCISION_INICIAL_DEFECTO = 0;
export const POCISION_UNO = 1;
export const POCISION_NOMINA = 0;
export const POCISION_REGION_COMUNA = 1;
export const POCISION_REGION = "REGION";
export const VALOR_DEFECTO_ENTERO = 0;
export const VALOR_NEGATIVO = -1;
export const VALOR_DEFECTO_STRING = "";
export const VALOR_VACIO = "";
export const VALOR_TEXTO_CERO = "0";
export const VALOR_TAMANO_VACIO = 0;
export const VALOR_TAMANO_UNO = 1;
export const VALOR_DIA_DIEZ = 10;
export const VALOR_MES_OCTUBRE = 9;
export const VALOR_ESPACIO = " ";
export const VALOR_SLASH = "/";
export const VALOR_SIGNO_GUION = "-";
export const VALOR_SIGNO_GUION_RARO = "‐";
export const VALOR_UN_DECIMAL = 1;
export const VALOR_DEFECTO_INCREMENTO_DECREMENTO = 1;
export const VALOR_CORRECCION_MES = 1;
export const VALOR_CORRECCION_ANIO = 2000;
export const VALOR_CONVERSION_BYTES = 1024;
export const VALOR_CONVERSION_HORAS = 24;
export const VALOR_CONVERSION_MINUTOS = 60;
export const VALOR_CONVERSION_SEGUNDOS = 60;
export const VALOR_CONVERSION_MILSEGUNDOS = 1000;
export const VALOR_NULL_STRING = "NULL";
export const VALOR_K_MAYUSCULA = "K";
export const VALOR_K_MINUSCULA = "k";
export const VALOR_TOKEN = "29f63634-d9ea-32d2-b8e3-5889262da5c9";
export const COD_CLIENTE = "901540233";
export const CONVERSION_POSICION_LECTURA = 1;
export const FECHA_INGLES = "date";
export const MES_INGLES = "month";
export const ANIO_INGLES = "year";
export const TIPO_EMAIL = "email";
export const TIPO_TEXTO = "text";
export const TIPO_STRING = "string";
export const COD_PETICON_SATISFACTORIA = 200;
export const COD_PETICION_OK = "001";
export const COD_MONEDA_UF = 3;
export const COD_HTTP_PROHIBIDO = 403;
export const COD_HTTP_NO_AUTORIZADO = 401;
export const INICIO_AUTENTICACION_TOKEN = "Bearer ";
export const HEADER_AUTENTICACION_TOKEN = "Authorization";
export const LISTA_UNIDADES_CONVERSION_TAMANO_ARCHIVO = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
export const REGEX_CORREO_ELECTRONICO = /^[a-zA-Z0-9-_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const REGEX_PUNTOS = /\./g;
export const REGEX_ESPACIOS_BLANCOS = /\s/g;
export const REGEX_COMIENZO_CERO = /^0/;
export const REGEX_CONTIENE_PUNTOS = /\./g;
export const REGEX_VALIDAR_RUT = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/;
export const ERROR_ESPACIOS = "El campo no puede tener espacios";
export const ERROR_VALOR_VACIO = "El campo no puede estar vacío";
export const ERROR_COMIENZO_CERO = "El campo no puede empezar con cero";
export const ERROR_CONTIENE_PUNTOS = "El campo no puede contener puntos";
export const ERROR_FORMATO_EMAIL = "El email no tiene un formato válido";
export const ERROR_FORMATO_RUT = "El RUT no tiene formato válido";
export const ERROR_FORMATO_FECHA = "Formato Fecha no válido ";
export const ERROR_FECHA_INICIO = "La fecha de inicio no debe ser anterior a #DIADESCONTAR# días";
export const ERROR_SIMBOLO_DIA_DESCONTAR = "#DIADESCONTAR#";
export const ERROR_REGION_INVALIDA = "La región no es válida";
export const ERROR_COMUNA_INVALIDA = "La comuna no es válida";
export const ERROR_FALTAN_CABECERAS_ARCHIVO = "Faltan las siguientes cabeceras en el archivo:";
export const ERROR_FALTAN_CABECERAS_FILA = "Falta llenar las siguientes columnas:";
export const FORMATO_FECHA_PETICION_UF = "YYYYMMDD";
export const FORMATO_FECHA_MOSTRAR = "DD-MM-YYYY";
export const FORMATO_CODIGO_ESPANOL = 'es-ES';
export const FORMATO_ESTILO_MONEDA = 'currency';
export const FORMATO_PESO_CHILENO = 'CLP';
export const DECIMALES_CLP = 0;
export const DECIMALES_UF = 3;
export const COLECCION_NOMINA = "nomina-covid";
export const COLECCION_CONFIGURACION = "configuracion-covid";
export const URL_BUSCAR_VALOR_MONEDA = "/contabilidad/valordiario/tipocambio/buscar/";
export const URL_PAGO_EMPRESA = "/toku";
export const URL_PAGO_CREAR = "/empresa/crear";
export const URL_PAGO_SOLICITAR = "/pago/solicitar";
export const URL_PAGO_COMPLEMENTO = "&idPortal=1";
export const FECHA_POSICION_DIA = 0;
export const FECHA_POSICION_MES = 1;
export const FECHA_POSICION_ANIO = 2;
export const CAMPO_EMPRESA_REGION = "EmpresaDomicilioRegion";
export const CAMPO_EMPRESA_COMUNA = "EmpresaDomicilioComuna";
export const SUFIJO_VALIDO = "Valido";
export const TEXTO_NOMBRE_DOCUMENTO = "Nombre Documento";
export const TEXTO_TAMANO = "Tamaño";
export const TEXTO_FECHA = "Fecha";
export const RUTA_PLANTILLA_EJEMPLO = "PLANTILLA_NOMINA_COVID_VC.xlsx";
export const NOTIFICACION_TIPO_ERROR = "danger";
export const NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES = "La cantidad de trabajadores ingresada en el formulario no coincide con la cantidad de trabajadores cargados en la nómina.";
export const NOTIFICACION_ERROR_RUT_CORREO = "Favor ingresar un rut contratante y un email contratante valido.";
export const NOTIFICACION_ERROR_RUT = "Favor ingresar un rut contratante valido.";
export const NOTIFICACION_ERROR_CORREO = "Favor ingresar un email contratante valido.";
export const NOTIFICACION_ERROR_CONECCION_FIREBASE = "Ocurrio un error al conectarse a la base de datos, por favor intente mas tarde.";
export const NOTIFICACION_ERROR_TOKEN = "Ocurrio un error al buscar el token, por favor intente mas tarde.";
export const NOTIFICACION_ERROR_UF_VACIO = "No se encontro el valor de la UF, por favor intente mas tarde.";
export const NOTIFICACION_ERROR_UF = "Ocurrio un error al buscar el valor de la UF, por favor intente mas tarde.";
export const NOTIFICACION_ERROR_CONFIGURACION_FIREBASE = "Ocurrio un error al buscar la configuración en la base de datos, por favor intente mas tarde.";
export const NOTIFICACION_ERROR_PAGO_TOKU = "Ocurrio un error al realizar el pago.";
export const REACT_APP_FIREBASE_CONFIG_DESA = "FIREBASE_CONFIG_DESA";
export const REACT_APP_FIREBASE_CONFIG_QA = "FIREBASE_CONFIG_QA";
export const REACT_APP_FIREBASE_CONFIG_PROD = "FIREBASE_CONFIG_PROD";
export const FIREBASE_CONFIG_DESA = {
  apiKey: "AIzaSyB-DrHANBvu0csaN4nsHX_gzPMfaRkLahU",
  authDomain: "desarrollo-dev-vc.firebaseapp.com",
  projectId: "desarrollo-dev-vc",
  storageBucket: "desarrollo-dev-vc.appspot.com",
  messagingSenderId: "462674343935",
  appId: "1:462674343935:web:313576780c85ff62e299ca"
};
export const FIREBASE_CONFIG_QA = {
  apiKey: "AIzaSyCkYGv0BvowVLCI9Mksym84IUA4yikuzzs",
  authDomain: "desarrollo-qas-vc.firebaseapp.com",
  projectId: "desarrollo-qas-vc",
  storageBucket: "desarrollo-qas-vc.appspot.com",
  messagingSenderId: "677680155917",
  appId: "1:677680155917:web:f8007f6c47faf38fb7b444"
};
export const FIREBASE_CONFIG_PROD = {
  apiKey: "AIzaSyBYhaSETwUEOaarIAojzbVGJjQjWWfWhoI",
  authDomain: "desarrollo-prd-vc.firebaseapp.com",
  projectId: "desarrollo-prd-vc",
  storageBucket: "desarrollo-prd-vc.appspot.com",
  messagingSenderId: "476678427494",
  appId: "1:476678427494:web:3d12178eeadd1299276ea7"
};
export const REGIONES_CON_COMUNAS = [
  {
      "Región Aisén del Gral.Carlos Ibáñez del Campo": [
          "Aisen",
          "Chile Chico",
          "Cisnes",
          "Cochrane",
          "Coihaique",
          "Guaitecas",
          "Lago Verde",
          "O'Higgins",
          "Rio Ibañez",
          "Tortel"
      ]
  },
  {
    "Región de Antofagasta": [
          "Antofagasta",
          "Calama",
          "Maria Elena",
          "Mejillones",
          "Ollagüe",
          "San Pedro de Atacama",
          "Sierra Gorda",
          "Taltal",
          "Tocopilla"
      ]
  },
  {
    "Región de Arica y Parinacota": ["Arica", "Camarones", "General Lagos", "Putre"]
  },
  {
    "Región de Atacama": [
          "Alto del Carmen",
          "Caldera", "Chañaral",
          "Copiapo",
          "Diego de Almagro",
          "Freirina",
          "Huasco",
          "Tierra Amarilla",
          "Vallenar"
      ]
  },
  {
    "Región de Coquimbo": [
          "Andacollo",
          "Canela",
          "Combarbala",
          "Coquimbo",
          "Illapel",
          "La Higuera",
          "La Serena",
          "Los Vilos",
          "Monte Patria",
          "Ovalle",
          "Paiguano",
          "Punitaqui",
          "Rio Hurtado",
          "Salamanca",
          "Vicuña"
      ]
  },
  {
    "Región de La Araucanía": [
          "Angol",
          "Carahue",
          "Cholchol",
          "Collipulli",
          "Cunco",
          "Curacautin",
          "Curarrehue",
          "Ercilla",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Melipeuco",
          "Nueva Imperial",
          "Padre Las Casas",
          "Perquenco",
          "Pitrufquen",
          "Pucon",
          "Puren",
          "Renaico",
          "Saavedra",
          "Temuco",
          "Teodoro Schmidt",
          "Tolten",
          "Traiguen",
          "Victoria",
          "Vilcun",
          "Villarrica"
      ]
  },
  {
    "Región de Los Lagos": [
          "Ancud",
          "Calbuco",
          "Castro",
          "Chaiten",
          "Chonchi",
          "Cochamo",
          "Curaco de Velez",
          "Dalcahue",
          "Fresia",
          "Frutillar",
          "Futaleufu",
          "Hualaihue",
          "Llanquihue",
          "Los Muermos",
          "Maullin",
          "Osorno",
          "Palena",
          "Puerto Montt",
          "Puerto Octay",
          "Puerto Varas",
          "Puqueldon",
          "Purranque",
          "Puyehue",
          "Queilen",
          "Quellon",
          "Quemchi",
          "Quinchao",
          "Rio Negro",
          "San Juan de la Costa",
          "San Pablo"
      ]
  },
  {
    "Región de Los Ríos": [
          "Corral",
          "Futrono",
          "La Union",
          "Lago Ranco",
          "Lanco",
          "Los Lagos",
          "Mafil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "Rio Bueno",
          "Valdivia"
      ]
  },
  {
    "Región de Magallanes y de la Antártica Chilena": [
          "Antartica",
          "Cabo de Hornos",
          "Laguna Blanca",
          "Natales",
          "Porvenir",
          "Primavera",
          "Punta Arenas",
          "Rio Verde",
          "San Gregorio",
          "Timaukel",
          "Torres del Paine"
      ]
  },
  {
    "Región de Ñuble": [
          "Bulnes",
          "Chillan",
          "Chillan Viejo",
          "Cobquecura",
          "Coelemu",
          "Coihueco",
          "El Carmen",
          "Ninhue",
          "Ñiquen",
          "Pemuco",
          "Pinto",
          "Portezuelo",
          "Quillon",
          "Quirihue",
          "Ranquil",
          "San Carlos",
          "San Fabian",
          "San Ignacio",
          "San Nicolas",
          "Treguaco",
          "Yungay"
      ]
  },
  {
    "Región de Tarapacá": ["Alto Hospicio", "Camiña", "Colchane", "Huara", "Iquique", "Pica", "Pozo Almonte"]
  },
  {
    "Región de Valparaíso": [
          "Algarrobo",
          "Cabildo",
          "Calera",
          "Calle Larga",
          "Cartagena",
          "Casablanca",
          "Catemu",
          "Concon",
          "El Quisco",
          "El Tabo",
          "Hijuelas",
          "Isla de Pascua",
          "Juan Fernandez",
          "La Cruz",
          "La Ligua",
          "Limache",
          "Llaillay",
          "Los Andes",
          "Nogales",
          "Olmue",
          "Panquehue",
          "Papudo",
          "Petorca",
          "Puchuncavi",
          "Putaendo",
          "Quillota",
          "Quilpue",
          "Quintero",
          "Rinconada",
          "San Antonio",
          "San Esteban",
          "San Felipe",
          "Santa Maria",
          "Santo Domingo",
          "Valparaiso",
          "Villa Alemana",
          "Viña del Mar",
          "Zapallar"
      ]
  },
  {
    "Región del Biobío": [
          "Alto BioBio",
          "Antuco",
          "Arauco",
          "Cabrero",
          "Cañete",
          "Chiguayante",
          "Concepcion",
          "Contulmo",
          "Coronel",
          "Curanilahue",
          "Florida",
          "Hualpen",
          "Hualqui",
          "Laja",
          "Lebu",
          "Los alamos",
          "Los Angeles",
          "Lota",
          "Mulchen",
          "Nacimiento",
          "Negrete",
          "Penco",
          "Quilaco",
          "Quilleco",
          "San Pedro de la Paz",
          "San Rosendo",
          "Santa Barbara",
          "Santa Juana",
          "Talcahuano",
          "Tirua",
          "Tome",
          "Tucapel",
          "Yumbel"
      ]
  },
  {
    "Región del Libertador Gral. Bernardo O’Higgins": [
          "Chepica",
          "Chimbarongo",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "La Estrella",
          "Las Cabras",
          "Litueche",
          "Lolol",
          "Machali",
          "Malloa",
          "Marchihue",
          "Mostazal",
          "Nancagua",
          "Navidad",
          "Olivar",
          "Palmilla",
          "Paredones",
          "Peralillo",
          "Peumo",
          "Pichidegua",
          "Pichilemu",
          "Placilla",
          "Pumanque",
          "Quinta de Tilcoco",
          "Rancagua",
          "Rengo",
          "Requinoa",
          "San Fernando",
          "San Vicente",
          "Santa Cruz"
      ]
  },
  {
    "Región del Maule": [
          "Cauquenes",
          "Chanco",
          "Colbun",
          "Constitucion",
          "Curepto",
          "Curico",
          "Empedrado",
          "Hualañe",
          "Licanten",
          "Linares",
          "Longavi",
          "Maule",
          "Molina",
          "Parral",
          "Pelarco",
          "Pelluhue",
          "Pencahue",
          "Rauco",
          "Retiro",
          "Rio Claro",
          "Romeral",
          "Sagrada Familia",
          "San Clemente",
          "San Javier",
          "San Rafael",
          "Talca",
          "Teno",
          "Vichuquen",
          "Villa Alegre",
          "Yerbas Buenas"
      ]
  },
  {
    "Región Metropolitana de Santiago": [
          "Alhue",
          "Buin",
          "Calera de Tango",
          "Cerrillos",
          "Cerro Navia",
          "Colina",
          "Conchali",
          "Curacavi",
          "El Bosque",
          "El Monte",
          "Estacion Central",
          "Huechuraba",
          "Independencia",
          "Isla de Maipo",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Lampa",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo", "Lo Prado",
          "Macul", "Maipu",
          "Maria Pinto",
          "Melipilla",
          "Ñuñoa",
          "Padre Hurtado",
          "Paine",
          "Pedro Aguirre Cerda",
          "Peñaflor",
          "Peñalolen",
          "Pirque",
          "Providencia",
          "Pudahuel",
          "Puente Alto",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "San Bernardo",
          "San Joaquin",
          "San Jose de Maipo",
          "San Miguel",
          "San Pedro",
          "San Ramon",
          "Santiago",
          "Talagante",
          "Tiltil",
          "Vitacura"
      ]
  },
]