import { useEffect } from 'react';
import { ContratanteProveedor } from '../../../reducer/Contratante/ContratanteProveedor';
import { Contratante } from './Contratante/Contratante';
import { Informacion } from './Informacion/Informacion'
import { Nomina } from './Nomina/Nonima';
import TagManager from "react-gtm-module";

export const Contenido = () => {

  useEffect(() => {    
    TagManager.dataLayer({
      dataLayer: {
        event: "traza",
        event_category: "Formulario Covid",
        event_label: "Carga nómina",
      },
    });
  },[]);

  return (
    <main className='container'>
      <ContratanteProveedor>
        <Informacion />
        <Contratante />
        <Nomina />
      </ContratanteProveedor>
    </main>
  );
}