import { useContext } from "react";
import { ConfiguracionContexto } from "../../../../reducer/Configuracion/ConfiguracionContexto";
import { AlertaIcono } from "../../../../util/IconosSvg"
import { Cargando } from "../../../../util/Cargando";
import { 
  DECIMALES_CLP,
  DECIMALES_UF,
  FORMATO_CODIGO_ESPANOL,
  VALOR_TAMANO_UNO, 
  VALOR_TAMANO_VACIO 
} from "../../../../constants/constantes";

const objFormatoClp = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
  minimumFractionDigits: DECIMALES_CLP,
  maximumFractionDigits: DECIMALES_CLP
});

const objFormatoUf = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
  minimumFractionDigits: DECIMALES_UF,
  maximumFractionDigits: DECIMALES_UF
});

export const Precio = ({ formulario }) => {

  const { precio : {precioBaseUF, valorUF, fechaUF, configurado} } = useContext(ConfiguracionContexto);
  let { trabajadoresCantidad } = formulario;
  let intCantidad              = Number(trabajadoresCantidad) > VALOR_TAMANO_VACIO ? trabajadoresCantidad : VALOR_TAMANO_UNO;
  let fltPrecioUF              = intCantidad * precioBaseUF;
  let fltPrecioClp             = fltPrecioUF * valorUF;

  if (configurado) {
    return (
      <>
        <section className="precio">
          <p className="precio_titulo">Precio total en pesos:</p>
          <h2 className="precio_pesos">${objFormatoClp.format(fltPrecioClp.toFixed(DECIMALES_CLP))} *</h2>
          <p className="precio_uf">{objFormatoUf.format(fltPrecioUF.toFixed(DECIMALES_UF))} UF</p>
          <p className="precio_nota">
            <AlertaIcono />
            Precio preferencial calculado con la uf del dia {fechaUF}, el precio en pesos puede variar según el día de facturación
          </p>
        </section>
      </>
    )
  }
  else{
    return(
      <Cargando
        claseColor="naranjo"
        idSpinner="spinnerNaranjo"
        posicionLogo="align-self-center w-100"
      />
    )
  }
}
