import React, { useContext } from 'react'
import { Button } from 'react-bootstrap';
import { ValidarNomina } from '../../../../util/Validacion-nomina';
import { ConfiguracionContexto } from '../../../../reducer/Configuracion/ConfiguracionContexto';
import { CerrarIcono, CheckIcono } from '../../../../util/IconosSvg';
import { ContratanteContexto } from '../../../../reducer/Contratante/ContratanteContexto';
import { mostrarNotificacion } from '../../../../util/Notificacion';
import { 
  NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES,
  NOTIFICACION_TIPO_ERROR 
} from '../../../../constants/constantes';

const validarNominaCovid = (objArchivo, nominaConfig, trabajadoresCantidad, guardarErrores) => {

  let intNominaTamano = Number(objArchivo.archivo.length);

  if (Number(trabajadoresCantidad) === intNominaTamano) {
    if (objArchivo.archivoCargado) {
      guardarErrores(ValidarNomina(objArchivo, nominaConfig));
    }
  }
  else{
    mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES);
  }
  
}

export const NominaValidar = ({ objArchivo, guardarErrores }) => {
  
  const { formulario: {trabajadoresCantidad} } = useContext(ContratanteContexto);
  const { nominaConfig } = useContext(ConfiguracionContexto);
  let strBtnVerificar = "btn--gris";

  if (objArchivo.archivoCargado) {
    strBtnVerificar  = "btn--naranja";
  }

  if (objArchivo.hayError) {
    strBtnVerificar = "btn--gris";
  }

  return (
    <>
      <p className="validacion_titulo">Verifica que el documento adjunto contenga la información requerida</p>
      <section className="validacion_contenedor">
        <Button 
          variant="light" 
          className={`btnVerificar ${strBtnVerificar}`}
          onClick={()=>{validarNominaCovid(objArchivo, nominaConfig, trabajadoresCantidad, guardarErrores)}}
          >
          Verificar
        </Button>
        { objArchivo.verificado && ( 
          objArchivo.hayError ? (
            <>
              <p className={`validacion_texto validacion_texto--error`}>Los datos ingresados en la nómina de colaboradores no estan correctos</p>
              <span className={`validacion_icono validacion_icono--error`}><CerrarIcono /></span>
            </>
          ) : (
            <>
              <p className={`validacion_texto`}>Los datos ingresados en la nómina de colaboradores se encuentran correctamente</p>
              <span className={`validacion_icono`}><CheckIcono /></span>
            </>
          )
        )}
      </section>
    </>
  )
}
