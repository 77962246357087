import ReactDOM from "react-dom";
import logoWhite from "../app/assets/images/logo-white.png";

export const Cargando = ({ claseColor, idSpinner, posicionLogo }) => {
  return ReactDOM.createPortal(
    <div className={claseColor}>
      <div className="container d-flex h-100">
        <div className={posicionLogo}>
          <img src={logoWhite} height="40px" width={250} alt="Vida camara logo" />
          <br />
          <div className="lds-roller mt-2">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(`#${idSpinner}`)
  );
};