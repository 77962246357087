import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { DescargarArchivoIcono } from '../../../../util/IconosSvg';
import PlantillaNominaCovid from '../../../assets/docs/PlantillaNominaCovid.xlsx';
import { RUTA_PLANTILLA_EJEMPLO } from '../../../../constants/constantes';

export const NominaEjemplo = () => {
  return (
    <Row>
      <Col md="12" lg="8" xl="9">
      <p className="seccionNomina_titulo">Ingresa la nómina de todos los colaboradores que deseas asegurar en el formato descargable</p>
      <p className="seccionNomina_parrafo">Todos los campos de la nómina deben ser completados a fin de evitar demoras en la contratación. Si el trabajador no tuviera registrado un correo personal se sugiere informar un correo institucional del propio colaborador para informarle la contratación del seguro </p>
      <p className="seccionNomina_parrafo"><strong> Debes usar el formato de región y comuna que está indicado en la segunda hoja del documento "Descargar Formato" para ingresar tu nómina</strong></p>
      </Col>
      <Col md="12" lg="4" xl="3" className="seccionNomina_cajaBtn">
        <a href={PlantillaNominaCovid}
          download={RUTA_PLANTILLA_EJEMPLO}
          target={"_blank"}
          rel="noopener noreferrer"
          >
          <Button variant="light" className="mb-1 btnDescargarFormato">
            <DescargarArchivoIcono />
            Descargar Formato
          </Button> 
        </a>
      </Col>
    </Row>
  )
}
