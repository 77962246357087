import { useFormulario } from "../Hooks/useFormulario";
import { ContratanteContexto } from "./ContratanteContexto"

const ContratanteInicializacion = {
  rut: null,
  correo: null,
  trabajadoresCantidad: 0,
  rutValido: true,
  correoValido: true,
}

export const ContratanteProveedor = ({children}) => {

  const { formulario, actualizarCampo, actualizarIncremento, actualizarDecremento } = useFormulario(ContratanteInicializacion);
 
  return (
    <ContratanteContexto.Provider value={{  formulario, actualizarCampo, actualizarIncremento, actualizarDecremento }}>
      { children }
    </ContratanteContexto.Provider>
  )
}