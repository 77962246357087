const LogoVC = require('../../assets/images/logo-white.png');

export const PiePagina = () => {
  return (
    <footer className='piePagina'>
      <figure className='piePagina_logo'>
        <img src={LogoVC} alt="Logo Vida Camara" />
      </figure>

      <ul className='piePagina_contacto'>
        <li className='piePagina_contacto--uppercase piePagina_contacto--negrita'>Para mayor información</li>
        <li>Puedes encontrarnos en el número</li>
        <li className='piePagina_contacto--negrita'>600 320 0700</li>
      </ul>
    </footer>
  );
}