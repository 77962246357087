import { LISTA_UNIDADES_CONVERSION_TAMANO_ARCHIVO, POCISION_INICIAL_DEFECTO, REGEX_CORREO_ELECTRONICO, VALOR_CONVERSION_BYTES, VALOR_DEFECTO_ENTERO, VALOR_DEFECTO_STRING, VALOR_ESPACIO, VALOR_UN_DECIMAL } from "../constants/constantes"

export const validarCorreoElectronico = (strCorreoElectronico) => {

  return REGEX_CORREO_ELECTRONICO.test(strCorreoElectronico);
}

export const convertirTamanoArchivo = (intBytes) => {

  
  if (intBytes === VALOR_DEFECTO_ENTERO) return VALOR_DEFECTO_STRING;

  let intDecimal        = VALOR_UN_DECIMAL;
  var lstTamano         = LISTA_UNIDADES_CONVERSION_TAMANO_ARCHIVO;
  var intPosicionTamano = parseInt(Math.floor(Math.log(intBytes) / Math.log(VALOR_CONVERSION_BYTES)));
  
  if (intPosicionTamano === POCISION_INICIAL_DEFECTO) 
    return intBytes + VALOR_ESPACIO + lstTamano[intPosicionTamano];
  
  return (intBytes / Math.pow(VALOR_CONVERSION_BYTES, intPosicionTamano)).toFixed(intDecimal) + VALOR_ESPACIO + lstTamano[intPosicionTamano];
};