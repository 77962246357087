import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { 
  FIREBASE_CONFIG_DESA, 
  FIREBASE_CONFIG_PROD, 
  FIREBASE_CONFIG_QA, 
  REACT_APP_FIREBASE_CONFIG_DESA, 
  REACT_APP_FIREBASE_CONFIG_PROD, 
  REACT_APP_FIREBASE_CONFIG_QA
} from '../constants/constantes';

const buscarConfiguracion = () => {
  if (process.env.REACT_APP_FIREBASE_CONFIG === REACT_APP_FIREBASE_CONFIG_DESA) {
    return FIREBASE_CONFIG_DESA;
  }
  if (process.env.REACT_APP_FIREBASE_CONFIG === REACT_APP_FIREBASE_CONFIG_QA) {
    return FIREBASE_CONFIG_QA;
  }
  if (process.env.REACT_APP_FIREBASE_CONFIG === REACT_APP_FIREBASE_CONFIG_PROD) {
    return FIREBASE_CONFIG_PROD;
  }
  return null;
}

export const configurarDb = async () => {
  const objApp = initializeApp(buscarConfiguracion());
  const objDb  = getFirestore(objApp);
  return objDb;
}

export const buscarColeccion = async (objDb, strNombreColeccion) => {

  const objColeccion   = collection(objDb, strNombreColeccion);
  const objInstantanea = await getDocs(objColeccion);
  const lstDocumento   = objInstantanea.docs.map(doc => doc.data());
  
  return lstDocumento;
}

export const guardarDocumento = async (objDb, objDocumento, strNombreColeccion) => {

  let objReferenciaDocumento = await addDoc(collection(objDb, strNombreColeccion), objDocumento);
  return objReferenciaDocumento;
}

export const actualizarDocumento = async (objDb, strNombreColeccion, strIdDocumento, objCamposNomina) => {

  return await updateDoc(doc(objDb, strNombreColeccion, strIdDocumento), objCamposNomina);
}