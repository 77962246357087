import { useContext, useEffect } from 'react'
import Axios from 'axios'
import { ConfiguracionContexto } from '../reducer/Configuracion/ConfiguracionContexto';
import { COD_HTTP_NO_AUTORIZADO, COD_HTTP_PROHIBIDO } from '../constants/constantes';

export const PeticionInterceptor = ({ children }) => {

    const { token, configurarToken } = useContext(ConfiguracionContexto);

    useEffect(() => {
      Axios.interceptors.request.use(
        (objConfiguracion) => {
          if (token) {
            objConfiguracion.headers.authorization = token;
          }
          return objConfiguracion;
        },
        (objError) => {
          return Promise.reject(objError);
        }
      );

      Axios.interceptors.response.use(
        async (response) => { return response },
        async (error) => {
          const originalRequest = error.config;
          const status = error.response.status;
      
          if (
            (status === COD_HTTP_PROHIBIDO || status === COD_HTTP_NO_AUTORIZADO) &&
            !originalRequest._retry ) {
            let strTokenNuevo = await configurarToken();
      
            originalRequest._retry = true
            originalRequest.headers.authorization = strTokenNuevo;
      
            return Axios(originalRequest)
          }
      
          return Promise.reject(error)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return children
}