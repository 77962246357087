import React from 'react';
import { NominaSeleccionar } from './NominaSeleccionar';
import { NominaInformacion } from './NominaInformacion';
import { Col, Row } from 'react-bootstrap';
import { NominaValidar } from './NominaValidar';
import { NominaError } from './NominaError';

export const NominaCargar = ({archivo, guardarArchivo, eliminarArchivo, guardarErrores}) => {

  return (
    <>
    <Row>
      <Col md="12" lg="6" xl="7">
        <section className="adjuntar">
          <NominaSeleccionar objArchivo={archivo} guardarArchivo={guardarArchivo} />
          <NominaInformacion objArchivo={archivo} eliminarArchivo={eliminarArchivo} />
        </section>
      </Col>
      <Col md="12" lg="6" xl="5">
        <NominaValidar objArchivo={archivo} guardarErrores={guardarErrores} />
      </Col>
    </Row>
    { archivo.hayError && (<NominaError errores={archivo.errores} />) }
    </>
  )
}
