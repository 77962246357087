import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Formulario } from './app/components/Formulario';
import { Exitoso } from './app/components/Exitoso';
import TagManager from "react-gtm-module";

class App extends Component {

  constructor(props) {
    super(props);
    TagManager.initialize({
      gtmId: process.env.REACT_APP_KEY_GOOGLE_TAG_MANAGER,
      dataLayer: {},
    });
  }

  render() {

    return (

      <BrowserRouter>
        <div>
          <Routes>
          <Route exact={true} path='/' element={<Formulario />} />
          <Route exact={true} path='/confirmacion-exitosa' element={<Exitoso />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
