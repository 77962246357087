import { GET,POST } from "../config/RestFactory";
import { 
  COD_PETICION_OK,
  COD_PETICON_SATISFACTORIA, 
  INICIO_AUTENTICACION_TOKEN,
  URL_BUSCAR_VALOR_MONEDA,
  URL_PAGO_EMPRESA,
  URL_PAGO_CREAR,
  URL_PAGO_SOLICITAR
} from "../constants/constantes";

export const buscarGatewayToken = async () => {
  try {
    return INICIO_AUTENTICACION_TOKEN + process.env.REACT_APP_AUTENTICACION_TOKEN;
  } catch (objError) {
   console.error(objError);
   return null;
  }
}

export const buscarUfPorFecha = async (intIdMoneda,strFechaHoy) => {
  
  try {
    let strUrl = `${process.env.REACT_APP_API_URL_CATALOGO}${URL_BUSCAR_VALOR_MONEDA}${intIdMoneda}/${strFechaHoy}`;
    return await GET(strUrl).then(objRespuesta => {
      if ( objRespuesta && COD_PETICON_SATISFACTORIA === objRespuesta.status &&
            objRespuesta.data.status.codigo === COD_PETICION_OK) {
        return objRespuesta.data;
      }
      return null;
    });
  } catch (objError) {
    console.error(objError);
    return null;
  }
}


export const crearCliente = async (objCliente) => {
  
  try {
    let strUrl = `${process.env.REACT_APP_API_URL_PAGO}${URL_PAGO_EMPRESA}${URL_PAGO_CREAR}`;// "https://apigatewaydev.vidacamara.cl:8243/pago/1.0.0/toku/empresa/crear";
    return await POST(strUrl,objCliente).then(objRespuesta => {

      if ( objRespuesta && COD_PETICON_SATISFACTORIA === objRespuesta.status &&
            objRespuesta.data.status.codigo === COD_PETICION_OK) {
        return objRespuesta.data.objeto;
      }
      return null;
    });
  } catch (objError) {
    console.error(objError);
    return null;
  }
}



export const crearSolicitudPago = async (objCliente) => {
  
  try {
    let strUrl = `${process.env.REACT_APP_API_URL_PAGO}${URL_PAGO_EMPRESA}${URL_PAGO_SOLICITAR}`;
    return await POST(strUrl,objCliente).then(objRespuesta => {

      if ( objRespuesta && COD_PETICON_SATISFACTORIA === objRespuesta.status &&
            objRespuesta.data.status.codigo === COD_PETICION_OK) {
        return objRespuesta.data.objeto;
      }
      return objRespuesta.data.objeto;
    });
  } catch (objError) {
    console.error(objError);
    return null;
  }
}